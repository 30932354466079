<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Upload Reports
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 650 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <div class="row" v-if="loading == false">
            <div class="col-12 col-sm-12 col-lg-5 col-xl-4">
              <form
                class="settings-form d-flex flex-column"
                action="#"
                @submit.prevent="uploadReports()"
              >
                <label for="" class="settings-label">Account</label>
                <div class="input-container">
                  <select
                    v-model="indexData"
                    class="select-input"
                    name="carrier"
                    id="carrier"
                  >
                    <option
                      v-for="(item, key) in account_data"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item.provider }} ( {{ item.account_number }} )
                    </option>
                    <!--
                      <option value="1">Telus (17512741)</option>
                      <option value="1">Bell (17512741)</option>
                      <option value="1">AT&T (17512741)</option>
                      <option value="1">Telus (54856984)</option>
                      <option value="1">Bell (35845985)</option>
                      <option value="1">AT&T (56941254)</option>
                      -->
                  </select>
                </div>

                <label for="" class="settings-label">File 1</label>
                <div class="file-input mb-15px">
                  <input
                    type="file"
                    id="file1"
                    name="file1"
                    @change.prevent="onChangeFile1()"
                    ref="file1"
                  />
                  <button>Choose File</button>
                  <p class="ml-3 mb-0 color-gray" v-if="this.file1 == ''">
                    No file chosen
                  </p>
                  <p class="ml-3 mb-0 color-gray" v-else>
                    {{ file1.name }}
                  </p>
                </div>

                <label for="" class="settings-label">File 2</label>
                <div class="file-input">
                  <input
                    type="file"
                    name="file1"
                    id="file2"
                    @change.prevent="onChangeFile2()"
                    ref="file2"
                  />
                  <button>Choose File</button>
                  <p class="ml-3 mb-0 color-gray" v-if="this.file2 == ''">
                    No file chosen
                  </p>
                  <p class="ml-3 mb-0 color-gray" v-else>
                    {{ file2.name }}
                  </p>
                </div>
                <div class="d-flex align-items-center mt-4">
                  <button type="submit" class="button-expertel mt-0">
                    Upload
                  </button>
                  <p class="color-orange mb-0 ml-3">
                    or
                    <router-link
                      :to="'/settings/multiple_upload'"
                      class="link color-orange"
                      >Multiple Upload</router-link
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      loading: true,
      notification_settings: [],
      account_data: [],
      indexData: 0,
      file1: "",
      file2: ""
    };
  },
  async created() {
    const self = this;
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        // this.calculateHeight();
        this.loading = false;
      }
    } else {
      this.$router.push("/");
    }

    //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/accounts`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.account_data = response.data;
      })
      .catch(error => this.makeToast("ERROR", error, "danger"));
  },
  methods: {
    onChangeFile1() {
      this.file1 = this.$refs.file1.files[0];
    },
    onChangeFile2() {
      this.file2 = this.$refs.file2.files[0];
    },
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    uploadReports() {
      //this.makeToast("Success", "Form submited", "success");
      const self = this;

      if (!self.indexData) {
        self.makeToast("ERROR", "Select an account", "danger");
        return false;
      }

      if (self.file1 || self.file2) {
        //
      } else {
        self.makeToast("ERROR", "Select at least a file", "danger");
        return false;
      }

      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      //let arrFiles = [];
      if (self.file1) {
        //arrFiles.push(self.file1);
        formData.append("file[]", self.file1);
      }

      if (self.file2) {
        //arrFiles.push(self.file2);
        formData.append("file[]", self.file2);
      }

      //if( arrFiles.length > 0)
      //{

      //console.log(self.indexData);
      //arrFiles.forEach(function(file) {
      //console.log(file);

      //formData.append("file", file);

      //api/v1/client/me/accounts/:account_id/file_upload

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/accounts/${self.indexData}/file_upload`,
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR", response.data.errors, "danger");
            self.loading = false;
          } else {
            self.makeToast(
              "SUCCESS",
              "Reports uploaded successfully",
              "success"
            );
            self.file1 = "";
            self.file2 = "";
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );

      //});
      //}
    }
  }
};
</script>
